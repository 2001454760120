import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';


function PrivateRoute() {


  const { currentUser } = useAuth();
  return currentUser ? <></> : <Navigate to="/login" />;
}

export default PrivateRoute;
